<template>
	<w-btn color="error" :disabled="disabled" :flat="flat" icon="delete" :loading="loading" :mini="mini" :small="small" v-on="$listeners">
		<template v-if="!noText">
			{{ text || $t('actions.delete') }}
		</template>
	</w-btn>
</template>

<script>
import ButtonParentClass from '@/components/Commons/Buttons/ButtonParentClass'

/**
 * @displayName w-btn-delete
 *
 * @since 0.3.2
 */
export default {
	name: 'WBtnDelete',
	extends: ButtonParentClass
}
</script>
