<script>
export default {
	name: 'ButtonParentClass',
	props: {
		color: {
			default: 'primary',
			required: false,
			type: String
		},
		disabled: {
			required: false,
			type: Boolean,
			default: () => false
		},
		flat: {
			required: false,
			type: Boolean,
			default: () => true
		},
		loading: {
			required: false,
			type: Boolean,
			default: () => false
		},
		mini: {
			required: false,
			type: Boolean,
			default: () => false
		},
		noText: {
			default: false,
			required: false,
			type: Boolean
		},
		small: {
			required: false,
			type: Boolean,
			default: () => false
		},
		text: {
			required: false,
			type: String,
			default: () => null
		}
	},
	methods: {
		emitClick: function () {
			this.$emit('click')
		}
	}
}
</script>
